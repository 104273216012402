import React from "react";
import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import get from "lodash/get";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import ContactForm from "@components/contact-form";

import "@scss/contact.scss";

export const contactQuery = graphql`
  query contactPageQuery($uid: String) {
    allPrismicContact(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title {
              text
            }
            content {
              text
            }
            header_image {
              url
            }
            contact_form_subjects {
              subject
              email
            }
          }
          uid
        }
      }
    }
  }
`;

const ContactPage = (props) => {
  var data = props.data;
  const currentPage = get(data, "allPrismicContact.edges[0].node");
  const contactSubjects = currentPage.data.contact_form_subjects;

  return (
    <Layout>
      <div className="general-page contact-page" id={currentPage.uid}>
        <section>
          {currentPage.data.title && (
            <h2>{RichText.asText(currentPage.data.title)}</h2>
          )}
          {currentPage.data.header_image && (
            <img src={currentPage.data.header_image.url} />
          )}
          {currentPage.data.content && (
            <div className="contact-page__content">
              {RichText.render(currentPage.data.content)}
            </div>
          )}
        </section>
        <section>
          <ContactForm contactSubjects={contactSubjects} />
        </section>
      </div>
    </Layout>
  );
};

export default ContactPage;
