import React, { Component } from "react";
import Classset from "react-classset";
import TextareaAutosize from "react-autosize-textarea";

import Select from "react-select";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: -1,
      status: "draft",
      error: false,
      errorMessage: null,
    };
    this.handleSubjectSelect = this.handleSubjectSelect.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
  }
  updateField(e) {
    let key = e.target.name;
    this.setState({ [key]: e.target.value });
  }
  handleFormSubmit(e) {
    e.preventDefault();

    console.log(this.state.name, this.state.email);

    if (!this.state.name || !this.state.email) {
      return;
    }

    console.log(e.target.elements);
    const encode = (data) => {
      const formData = new FormData();
      Object.keys(data).forEach((k) => {
        formData.append(k, data[k]);
      });
      return formData;
    };

    this.setState({ sending: true });

    const userName = e.target.elements.name.value;
    const userEmail = e.target.elements.email.value;
    const messageBody = e.target.elements.message.value;
    const messageSubject = e.target.elements.subject.value;
    const allSubjects = this.props.contactSubjects;

    fetch("/", {
      method: "POST",
      // headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name: userName,
        subject: `Website Inquiry - ${allSubjects[messageSubject].subject}`,
        email: userEmail,
        message: messageBody,
      }),
    }).then((response) => {
      if (response.ok) {
        setTimeout(() => {
          this.setState({ status: "sent" });
        }, 600);
      } else {
        this.setState({
          error: true,
          sending: false,
          errorMessage: "Something went wrong...",
        });
      }
    });
  }
  handleSubjectSelect(data) {
    this.setState({
      selectedSubject: data.value,
    });
  }
  render() {
    let contactSubjects = this.props.contactSubjects;
    let contactFormClasses = Classset({
      "contact-form__body": true,
      "contact-form__body--active": this.state.selectedSubject !== -1,
    });

    let formSuccessMessage = () => (
      <>
        <div className="contact-form__response">
          <h2>Your message has been sent.</h2>
          <div className="contact-page__content">
            <h3>We'll be in touch shortly.</h3>
          </div>
        </div>
      </>
    );
    let formBody = ({ errorMessage, error }) => {
      let errorHtml = error ? <span>{errorMessage}</span> : null;

      let selectOptions = contactSubjects.map((subject, index) => {
        return { value: index, label: subject.subject };
      });

      const colorStyles = {
        control: (styles) => ({
          ...styles,
          color: "black",
          borderRadius: 0,
          border: "none",
        }),
        option: (styles) => ({ ...styles, color: "black" }),
        label: (styles) => ({ ...styles, color: "black", fontSize: "16px" }),
        placeholder: (styles) => ({
          ...styles,
          color: "black",
          fontSize: "16px",
        }),
      };

      return (
        <>
          <div className="contact-form__field">
            <h4>Send Us An Email</h4>
            {/* <label for="contact-form-subject">Send Us an Email:</label> */}
            <Select
              id="contact-form-subject"
              name="subject"
              onChange={this.handleSubjectSelect}
              options={selectOptions}
              styles={colorStyles}
              label="Single select"
            />
          </div>
          <div className={contactFormClasses}>
            <div className="contact-form__field">
              <input
                type="text"
                name="name"
                placeholder="Name"
                id="contact-form-name"
                onChange={this.updateField}
              />
            </div>
            <div className="contact-form__field">
              <input
                type="email"
                placeholder="Email"
                name="email"
                id="contact-form-email"
                onChange={this.updateField}
              />
            </div>
            <div className="contact-form__field contact-form__field--last">
              <TextareaAutosize
                name="message"
                id="contact-form-message"
                placeholder="Message"
                rows="4"
              />
            </div>
            <div className="contact-form__field contact-form__field--submit">
              <button type="submit" className="button">
                Send Email
              </button>
              <div className="contact-form__inline-error">{errorHtml}</div>
            </div>
          </div>
        </>
      );
    };

    let contents =
      this.state.status === "draft"
        ? formBody({ ...this.state })
        : formSuccessMessage();
    return (
      <form
        data-netlify="true"
        className="contact-form"
        // data-netlify-honeypot="not-today"
        method="post"
        action="/"
        name="contact"
        // ref={formEl}
        onSubmit={this.handleFormSubmit}
      >
        {contents}
      </form>
    );
  }
}

export default ContactForm;
